import * as React from 'react'
import { Layout } from '../components/Layout'

const workExperience = [
  {
    id: '1',
    company: 'Vehikl',
    role: 'Senior Developer / Product Designer',
    duration: 'August 2015 – Present',
    description:
      'At Vehikl I design and develop web applications for clients that are professional, engaging, user-friendly, and optimized for all devices',
    points: [
      `Communicating and presenting design ideas and concepts to clients`,
      `Front-end development using popular frameworks such as React and VueJS`,
      `Develop high quality front-end layouts using CSS`,
      `Produce clean and accessible HTML code`,
      `Create well architected and tested component based front-end applications`,
      `Design engaging, user-friendly interfaces that are accessible and responsive`,
      `Mentor and teach front-end development`,
    ],
  },
  {
    id: '2',
    company: 'Dricore Products',
    role: 'Graphics & Marketing Coordinator',
    duration: 'June 2012 - August 2015',
    description: `Responsible for providing all graphics and web content from conception to final product and playing a key role in all marketing activities. Main responsibilities included:`,
    points: [
      `Complete redesign of AGT Products websites`,
      `Creating web graphics and mobile app designs`,
      `Design and development of user interface, wireframes and mockups`,
      `Strategy and SEO optimization`,
      `Creating promotional pieces – ads, brochures, POP displays and stationary`,
      `Making 3D renderings`,
    ],
  },
  {
    id: '3',
    company: 'The UPS Store',
    role: 'Graphic Designer',
    duration: 'Jan 2011 – June 2012',
    description: ``,
    points: [
      `Developed concepts and designed materials for promotional pieces, website design, business cards and email marketing`,
      `Prepared files for print`,
      `Presented design ideas and concepts to clients`,
    ],
  },
]

const Job = ({ job }: { job: any }) => {
  return (
    <div className="mb-8">
      <h2 className="font-sans-bold text-gray-800 dark:text-gray-200 text-xl mb-2">
        {job.company}
      </h2>
      <p className="italic">{job.role}</p>
      <p className="mb-2">{job.duration}</p>
      <p className="mb-4">{job.description}</p>
      <ul className="list-disc pl-4 dark:text-gray-300 text-gray-800">
        {job.points &&
          job.points.map((point: string, index: number) => (
            <li key={index}>{point}</li>
          ))}
      </ul>
    </div>
  )
}

const Education = () => {
  return (
    <div className="dark:text-gray-300 text-gray-800">
      <p>Graphic Design – Coop Diploma</p>
      <p>Mohawk College, Hamilton ON</p>
    </div>
  )
}

const Skills = () => {
  return (
    <ul className="list-disc pl-4 dark:text-gray-300 text-gray-800">
      <li>Typescript</li>
      <li>Javascript</li>
      <li>CSS</li>
      <li>HTML</li>
      <li>UI / UX Design</li>
      <li>Accessibility</li>
      <li>Design systems</li>
    </ul>
  )
}

const Tools = () => {
  return (
    <ul className="list-disc pl-4 dark:text-gray-300 text-gray-800">
      <li>Visual studio code</li>
      <li>Hyper</li>
      <li>Figma</li>
      <li>Sketch</li>
      <li>Adobe creative suite</li>
    </ul>
  )
}

const Frameworks = () => {
  return (
    <ul className="list-disc pl-4 dark:text-gray-300 text-gray-800">
      <li>React</li>
      <li>Vue</li>
      <li>Angular</li>
      <li>ExpressJS</li>
      <li>Tailwind CSS</li>
      <li>Styled components</li>
      <li>SCSS</li>
    </ul>
  )
}

export const Resume = () => {
  return (
    <Layout title="Resume" description="Darren Galway's resume">
      <main className="container mx-auto lg:max-w-4xl px-4 pt-12 lg:pt-24 animate-in text-gray-700 dark:text-gray-400">
        <div className="lg:flex mb-12 lg:mb-24 relative">
          <div className="lg:flex-1">
            <h2 className="text-xl lg:text-2xl mb-8 text-gray-800 dark:text-gray-300 font-sans-bold relative lg:sticky lg:top-[96px] after:content-[''] after:absolute after:h-1 after:rounded-full after:left-0 after:-bottom-[.5em] after:w-8 after:top-auto after:bg-blue-600">
              Work experience
            </h2>
          </div>
          <div className="flex-1">
            {workExperience.map((job) => (
              <Job job={job} key={job.id} />
            ))}
          </div>
        </div>
        <div className="lg:flex mb-12 lg:mb-24">
          <div className="lg:flex-1">
            <h2 className="text-xl lg:text-2xl mb-8 text-gray-800 dark:text-gray-300 font-sans-bold relative lg:sticky lg:top-[96px] after:content-[''] after:absolute after:h-1 after:rounded-full after:left-0 after:-bottom-[.5em] after:w-8 after:top-auto after:bg-blue-600">
              Education
            </h2>
          </div>
          <div className="flex-1">
            <Education />
          </div>
        </div>
        <div className="lg:flex mb-12 lg:mb-24">
          <div className="lg:flex-1">
            <h2 className="text-xl lg:text-2xl mb-8 text-gray-800 dark:text-gray-300 font-sans-bold relative lg:sticky lg:top-[96px] after:content-[''] after:absolute after:h-1 after:rounded-full after:left-0 after:-bottom-[.5em] after:w-8 after:top-auto after:bg-blue-600">
              Frameworks
            </h2>
          </div>
          <div className="flex-1">
            <Frameworks />
          </div>
        </div>
        <div className="lg:flex mb-12 lg:mb-24">
          <div className="lg:flex-1">
            <h2 className="text-xl lg:text-2xl mb-8 text-gray-800 dark:text-gray-300 font-sans-bold relative lg:sticky lg:top-[96px] after:content-[''] after:absolute after:h-1 after:rounded-full after:left-0 after:-bottom-[.5em] after:w-8 after:top-auto after:bg-blue-600">
              Skills
            </h2>
          </div>
          <div className="flex-1">
            <Skills />
          </div>
        </div>
        <div className="lg:flex mb-12 lg:mb-24">
          <div className="lg:flex-1">
            <h2 className="text-xl lg:text-2xl mb-8 text-gray-800 dark:text-gray-300 font-sans-bold relative lg:sticky lg:top-[96px] after:content-[''] after:absolute after:h-1 after:rounded-full after:left-0 after:-bottom-[.5em] after:w-8 after:top-auto after:bg-blue-600">
              Tools
            </h2>
          </div>
          <div className="flex-1">
            <Tools />
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Resume
